
.footer{
    margin-top: 120px;
}
.footer_img img{
    display: block;
    width: 70%;
    margin: 0 auto 25px;
}

.footer_info_list{
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    list-style-type: none;
}

.collapsible label{
    position: relative;
    display: block;
    margin-bottom: 10px;
    padding: 15px 0;
    z-index: 1;
    border-bottom: 1px solid #00000020;
    text-transform: uppercase;
}

.collapsible input{
    display: none;
}

.collapsible label::after{
    content: '';
    position: absolute;
    right: 0;
    top: 17px;
    width: 27px;
    height: 16px;
    background: url(../assets/icons/to-top.svg) no-repeat 0 0;
    transition: all .4s ease-in-out;
}

.collapsible input:checked + label::after{
    transform: rotate(180deg);
}

.collapsible_info{
    max-height: 1px;
    overflow: hidden;
    position: relative;
    top: -100%;
    opacity: .5;
    transition: all .5s ease-in-out;
}

.collapsible input:checked ~ .collapsible_info{
    max-height: 200px;
    opacity: 1;
    top: 0;
}

.collapsible_info li{
    font-size: .9em;
}

.footer p{
    text-align: center;
}


@media only screen and (min-width: 768px) {
    .footer_img img{
        width: 50%;
    }
}


@media only screen and (min-width: 1024px) {
    .footer{
        padding-top: 50px;
        margin-top: 50px;
    }
    .footer_img{
        display: flex;
        align-items: center;
        padding-bottom: 30px;
    }
    .footer_img img{
        width: 200px;
        margin: inherit;
    }
    .footer_img::after{
        content: '';
        display: inline-block;
        width: 65%;
        height: 1px;
        border-bottom: 1px solid #00000020;
        margin-left: 5%;
    }
    .collapsible_info{
        max-height: 280px;
        opacity: 1;
        top: 0;
    }
    .footer_info_list{
        flex-direction: row;
        justify-content: space-between;
    }
        
    .collapsible label::after{
        display: none;
    }
    .collapsible label{
        border: 0;
    }
    .footer_copyright{
        padding-top: 20px;
    }
}