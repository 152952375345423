.body{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
    margin: 0;
    overflow: hidden;
  }
  
  .spinner {
    display: grid;
    grid-template-columns: repeat(3, 20px);
    grid-template-rows: repeat(3, 20px);
    grid-gap: 5px;
    justify-items: center;
    align-items: center;
  }
  
  .square {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    box-shadow: 0 0 5px rgba(245, 225, 198, 0.5);
    animation: scaleInOut 1s infinite;
  }
  
  @keyframes scaleInOut {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.7);
    }
  }
  