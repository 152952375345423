.slider_one{
    height: 50vh;
}

.slider_two_desktop{
    display: none;
}

.mySwiper{
    display: none;
}

.second_slider{
    display: none;
    padding: 30px 0;
}

.mobile_slider{
    position: relative;
    margin-bottom: 20px;
}
.mobile_slider h3{
    position: absolute;
    bottom: 20px;
    left: 10px;
    font-size: 1.3em;
    font-weight: 200;
    color: #fff;
    text-transform: capitalize;
    text-align: left;
}
@media only screen and (min-width: 768px) {
    .second_slider{
        display: block;
        overflow: hidden;
        padding-left: 10px;
    }

    /* second slider */

    .slider_blocks{
        position: relative;
        overflow: hidden;
    }

    .block_main{
        position: relative;
        width: 350px;
        height: 400px;
        background-color: antiquewhite;
        overflow: hidden;
    }
    .secondary_blocks{
        position: absolute;
        top: 50%;
        left: 200px;
        transform: translateY(-50%);
        display: flex;
    }
    .block_secondary{
        width: 300px;
        height: 300px;
        background-color: aliceblue;
        margin-right: 40px;
    }

    .navigation_bar{
        position: absolute;
        bottom: 20px;
        left: 20px;
        width: 150px;
        display: flex;
        justify-content: space-between;
    }

    .navigation_bar div{
        width: 70px;
        height: 70px;
        background-color: aliceblue;
        border-radius: 50%;
        position: relative;
        z-index: 1;
    }
    .navigation_bar div:first-child::after{
        content: '';
        display: inline-block;
        background: url(../../assets/icons/to-top.svg) no-repeat;
        width: 26px;
        height: 15px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
    }
    .navigation_bar div:last-child::after{
        content: '';
        display: inline-block;
        background: url(../../assets/icons/to-top.svg) no-repeat ;
        width: 26px;
        height: 15px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(90deg);
    }

    .inside_block{
        position: absolute;
        width: 350px;
        height: 100%;
        background-color:antiquewhite;
        display: flex;
        left: 0;
        transition: all .4s ease-in;
    }

    .main_inside{
        width: 350px;
        position: absolute;
        background-color: azure;
    }

    .inside_product{
        position: absolute;
        top: 20px;
        left: 15px;
        color: #fff;
        font-weight: 300;
    }

    .inside_product h3{
        font-weight: 300;
        font-size: 1.8em;
    }
    .secondary_inside_product{
        position: absolute;
        bottom: 20px;
        margin-left: 15px;
        color: #fff;
        font-weight: 300;
    }
    
    .secondary_inside_product h3{
        font-weight: 300;
        font-size: 1.8em;
    }
    .secondary_blocks{
        overflow: hidden;
    }
}

@media only screen and (min-width: 1170px) {
    .slider_one{
        display: none;
    }
    .slider_two_desktop{
        height: 50vh;
        display: block;
    }
    .desktop_inner_block{
        display: flex!important;
        justify-content: space-between;
        
    }
    .second_slider{
        padding: 40px 0;
        background-position: 0 -100px;
    }
    .title{
        padding-bottom: 15px;
        font-weight: 400;
        font-size: 2em;
    }
    .slider_blocks{
        max-width: 1170px;
        overflow: visible;
        margin: 0 auto;
    }
    .block_main{
        width: 450px;
        height: 500px;
    }
    
    .main_inside{
        width: 450px;
    }

    .block_secondary{
        width: 400px;
        height: 370px;
    }
    .secondary_blocks{
        position: absolute;
        top: 55%;
        left: 300px;
    }
}