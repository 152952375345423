.history_header{
    background: url(https://images.unsplash.com/photo-1447727214830-cbcbf097b52c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1596&q=80) center ;
    background-size: cover;
    position: relative;
    height: 40vh;
}
.history_header::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.history_header h1{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 300;
    color: #fff;
    z-index: 1;
}
.progression_inner {
    width: 100%;
    height: 100%;
}
  
.progression_inner{
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
  
.inner_item img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}


.history_body{
    padding: 0 10px;
}
  
.history_progression{
    padding: 5px;
    margin-bottom: 15px;
    display: block;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 1px 10px #00000050;
}

.history_title{
    padding: 20px 0;
}

.title_text{
    padding-bottom: 20px;
}

.title_text h2{
    font-size: 1.2em;
    font-weight: 400;
    padding-bottom: 15px;
}

.title_text p{
    text-align: center;
    padding-bottom: 10px;
}

.history_title_image{
    background-color: azure;
    height: 25vh;
}

.history_progression h3{
    font-size: 2em;
    font-weight: 500;
    margin-bottom: 40px;
}

.inner_item p{
    text-align: justify;
    font-weight: 300;
    padding-bottom: 20px;
}

.history_nowaday h4{
    font-size: 1.3em;
    font-weight: 400;
    padding-bottom: 10px;
}

.history_adv_list p{
    padding: 20px 0;
    font-weight: 300;
    font-size: 1.1em;
}

.history_adv_list ul{
    list-style-type: none;
    padding-bottom: 40px;
}

.history_adv_list ul li{
    letter-spacing: 1px;
    padding-bottom: 20px;
}

.partners{
    padding-bottom: 30px;
}

.partners h3{
    font-size: 1.2em;
    font-weight: 400;
    padding-bottom: 10px;
}


@media only screen and (min-width: 768px) {
    .history_title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 70px 0;
    }
    .history_title_image{
        max-width: 300px;
        height: 220px;
        width: 100%;
    }
    .inner_item{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .inner_item div{
        width: 45%;
    }
    .inner_item p{
        max-width: 400px;
    }
    .inner_item img{
        max-width: 300px;
    }
    .history_nowaday h4{
        text-align: center;
        position: relative;
        padding: 40px 0;
        margin-bottom: 60px;
    }
    .history_nowaday h4::after{
        content: "";
        display: block;
        width: 300px;
        height: 1px;
        background-color: #00000010;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
    }
    .history_nowaday h4::before{
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: #00000040;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
    } 
}
@media only screen and (min-width: 1024px) {
    .partners h3{
        font-size: 1.6em;
        text-align: center;
    }
    .history_title_image{
        max-width: 400px;
    }
    .inner_item p{
        max-width: 500px;
    }
    .inner_item img{
        max-width: 400px;
    }
    .history_progression{
        margin-bottom: 100px;
    }
    .history_adv_list p{
        padding-bottom: 40px;
    }
}

@media only screen and (min-width: 1170px) {
    .progression_inner h3{
        position: relative;
        padding: 0 20px;
        z-index: 2;
    }
    .progression_inner h3 h2{
        background-color: #fff;
        display: inline-block;
        padding: 0 25px;
    }
    .progression_inner h3::before{
        left: 0;
        top: 50%;
        content: '';
        display: inline-block;
        width: 100%;
        position: absolute;
        height: 1px;
        background-color: #00000040;
        z-index: -20;
    }
    .history_title_image{
        max-width: 500px;
        height: 400px;
    }
    .inner_item{
        justify-content: space-around;
    }
    .inner_item img{
        margin-right: 100px;
    }
    
}