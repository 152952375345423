.catalog{
    padding-bottom: 40px;
    position: relative;
}


.catalog_header{
    height: 300px;
    background-color: antiquewhite;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.catalog_header h1{
    font-weight: lighter;
    font-size: 3em;
}

.catalog_body{
    padding: 0 15px;
}

.filter{
    z-index: 3;
    position: relative;
}

.filter h2{
    font-weight: 400;
    padding: 10px 10px;
    position: relative;
    background-color: #00000025;
    border-radius: 5px;
    cursor: pointer;
}

.filter h2::after{
    content: '';
    background: url('../assets/icons/to-top.svg') 0 0 no-repeat;
    display: block;
    width: 26px;
    height: 15px;
    position: absolute;
    top: 30%;
    right: 2%;
    rotate: 90deg;
    transform: translateY(-50%);
}
.filters_params{
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    overflow-y: scroll;
    background-color: #ffffff;    
    scrollbar-width: auto;
    scrollbar-color: #00000030 #000000;
}

.filters_params::-webkit-scrollbar {
    width: 16px;
}

.filters_params::-webkit-scrollbar-track {
    background: #ffffff;
}

.filters_params::-webkit-scrollbar-thumb {
    background-color: #00000030;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }

.closed_filter{
    left: -100%!important;   
}

.opened_filter{
    left: 0;
}

.filter_header{
    position: relative;
    border-bottom: 1px solid #00000040;
    height: 120px;
}

.filter_header h3{
    font-weight: 400;
    font-size: 1.7em;
    position: absolute;
    left: 30px;
    top: 50px;
}

.params_box{
    padding: 40px 30px;
}

.params_item{
    border-bottom: 1px solid #00000040;
    position: relative;
    margin-bottom: 15px;
}

.collapsible{
    display: none;
}

.param_type{
    display: block;
    font-size: 1.7em;
    font-weight: 500;
    text-transform: capitalize;
    letter-spacing: 3px;
    margin-bottom: 10px;
    position: relative;
}

.param_type::after{
    transition: all .5s ease!important;
    content: '';
    display: block;
    width: 30px;
    height: 1px;
    background-color: #212E28;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

.params{
    transition: all .5s ease!important;
    display: flex;
    align-items: center;
    opacity: 0;
    height: 0;
}

.collapsible:checked ~ .params{
    opacity: 1;
    padding-bottom: 10px;
    height: 35px;
}



.collapsible:checked ~ .param_type::after{
    rotate: -90deg;
    top: 35%;
}

.params input{
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.params label{
    font-weight: 500;
    font-size: 1.3em;
    text-transform: uppercase;
    letter-spacing: 3px;
}

.close_btn{
    width: 30px;
    filter: contrast(0%);
    position: absolute;
    right: 30px;
    top: 50px;
}
.items{
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
}
.next_btn{
    position: absolute;
    bottom: -105px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
}    
.next_btn button{
    padding: 5px 25px;
    font-size: 1.2em;
    font-weight: lighter;
    border: 0;
    cursor: pointer;
    background-color: #6ea483;
    color: white;
    transition: all .5s;
    border-radius: 4px;
    box-shadow: 1px 1px 3px #00000020;
}
.next_btn button:hover{
    background-color: #4bcb7c;
}
.search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    margin-bottom: 20px;
    position: relative;
}

.mobile_seacrh_box{
    position: absolute;
    top: 40px;
    border-radius: 5px;
    box-shadow: 1px 1px 15px #00000020;
}

.find_item_mobile{
    background-color: #fff;
    padding: 10px 5px;
    border-bottom: 1px solid #00000030;
    display: flex;
    align-items: center;
}

.find_item_image{
    width: 50px;
    height: 50px;
}

.search_input{
    padding: 0 15px;
    width: 90%;
    border: 1px solid #00000030;
    border-radius: 15px;
    height: 30px;
}

.search_btn{
    width: 20px;
    height: 20px;
}



.item_background{
    height: 265px;
    position: relative;
    transition: all 2s ease-in;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}
.gradient_anim{
    opacity: 0;
    transform: rotate(150deg) translateX(20%);
    position: absolute;
    top: -70%;
    height: 200px;
    width: 300%;
    background: linear-gradient(180deg, rgba(206, 206, 206, 0) 0%, rgb(255, 255, 255) 50%, rgba(0,212,255,0) 100%);left: 0;
    transition: all 1.5s ease;
}

.item_background:hover .gradient_anim{
    opacity: 1;
    top: 100%;
}

.item_titles{
    padding-top: 10px;
}

.item_titles p:first-child{
    font-size: item;
    font-weight: 300;
    text-transform: uppercase;
    color: #4B6258;
}
.item_titles p:last-child{
    font-weight: 400;
    font-size: 16;
    color: #212E28;
}
.item_gradient{
    margin-bottom: 20px;
    background: linear-gradient(153deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 51%, rgba(112,140,181,0) 100%)!important;
    width: 100%;
}

.search_desktop{
    display: none;
}

@media only screen and (min-width: 768px) {
    .closed_bg::after{
        transition: all 1s ease-out;
        content: '';
        width: 0;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-color: #00000000;
        box-shadow: 10px 2px 57px 2px rgba(0,0,0,0);
    }
    .opened_bg::after{
        transition: all 1s ease-in-out;
        content: '';
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-color: #00000050;
        box-shadow: 10px 2px 57px 2px rgba(0,0,0,.45);
    }
    .filters_params{
        width: 40%;
    }
    .items{
        min-height: 1100px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
    .filter_search{
        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-gap: 30px;
        align-items: center;
        vertical-align: middle;
    }
    .search{
        margin: 0;
    }
    .items{
        margin-top: 10px;
    }
    .filter h2::after{
        right: 5%;
    }
    .search{
        justify-content: space-around;
    }
}

/* PRODUCT */

.product_img{
    height: 50vh;
    background-color: antiquewhite;
    position: relative;
}

.product_img h1{
    position: absolute;
    bottom: 10px;
    left: 5px;
    font-weight: 400;
    color: #fff;
}

.product_info{
    padding: 15px 5px;
}

.product_info h1{
    margin-bottom: 15px;
    font-weight: 300;
}

.product_adv{
    display: flex;
    flex-direction: column;
}

.the_cover{
    padding-top: 10px;
}

.the_cover > div{
    padding-bottom: 10px;
}
.the_cover > div h4{
    font-weight: 500;
    text-transform: capitalize;
}

.inner_setting{
    padding: 5px 10px;
    margin-top: 3px;
    text-align: center;
    border: 1px solid #00000020;
    text-align: left;
}

.inner_setting_size{
    margin: 0 auto;
    text-align: center;
    position: relative;
    margin-bottom: 20px;
}

.inner_setting_size p{
    font-size: .9em;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    color: #000;
}
.swiper {
    width: 100%;
    height: 100%;
}


.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.product_slider_item{
    height: 200px;
    margin: 10px 0;
    width: 100%;
    background-color: #00000080;
}

.similar_products{
    margin-top: 10px;
}

.similar_products > div{
    background-color: #00000010;
    height: 220px;
    max-width: 250px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
}

.size h4{
    font-weight: 500;
    text-transform: capitalize;
}

.similar_product{
    position: relative;
}

.similar_product>div{
    position: absolute;
    bottom: 10px;
    left: 5px;
    color: #fff;
}

.similar_product h3{
    width: 80%;
    font-weight: 400;
}

.images_gallery{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}
@media only screen and (min-width: 555px) {
    .product_props{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-column-gap: 25px;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 15px;
    }
    .product_slider_item{
        height: 300px;
        max-width: 550px;
        margin: 5px auto;
    }
    .mySwiper{
        max-width: 550px!important;
    }
    .similar_products{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .size{
        margin-left: 30px;
    }
}

@media only screen and (min-width: 1024px) {
    .item_background{
        height: 345px;
    }
    .product_slider_item{
        max-width: none!important;
        height: 600px;
    }
    .mySwiper{
        max-width: none!important;
    }
    .product_props{
        justify-content: start;
    }
    .seriler{
        max-width: 1000px;
        margin: 0 auto;
    }
    .seriler h3{
        margin-bottom: 10px;
        font-size: 1.3em;
    }
    .similar_products{
        max-width: 1000px;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 20px;
    }
    .similar_products > div{
        max-width: none;
        width: 100%;
        height: 300px;
    }
    .the_cover{
        margin-right: 30px;
    }
}

@media only screen and (min-width: 1160px) {
    .items{
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media only screen and (min-width: 1360px) {
    .axtar{
        display: none;
    }
    .catalog_body{
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-gap: 20px;
        position: relative;
    }
    .filter_search{
        display: none;
    }
    .search_desktop{
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: relative;
    }
    .seacrh_box{
        position: absolute;
        padding-bottom: 30px;
        width: 100%;
        background-color: #fff;
        top: 100%;
    }

    .find_item{
        height: 70px;
        border-bottom: 1px solid #00000010;
        display: flex;
        align-items: center;
        padding: 25px;
    }

    .find_item:hover{
        background-color: #f3f3f3;
        cursor: pointer;
    }

    .find_item_image{
        min-width: 50px;
        min-height: 50px;
    }

    .find_item_title h3{
        font-weight: 400;
        margin-left: 5px;
    }
    .filters_params{
        display: block;
        position: absolute;
        top: -100px;
        width: 100%;
        border: 1px solid #00000030;
        overflow: scroll;
    }
    .opened_bg::after{
        display: none;
    }
    .close_btn{
        display: none;
    }
    .param_type{
        font-size: 1.2em;
    }
    .params label{
        font-size: 1em;
    }
    .collapsible:checked ~ .params{
        height: 25px;
    }
    .item_side{
        max-width: 1025px;
        width: 100%;
        margin: 0 auto
    }
    .product_slider_item{
        height: 100vh;
    }
    .similar_products{
        grid-template-columns: 1fr 1fr;
    }
    .similar_products > div{
        height: 350px;
    }
    .gallery_image{
        max-height: 450px;
        margin: 15px;
    }
}