.portfolio{
    color: #393939;
}
.portfolio h3{
    font-size: 1.6em;
    font-weight: 400;
    letter-spacing: 1px;
    margin: 50px 0 20px 0;
}

/* PARTNERS */

.partners{
    padding: 0 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
}

.partners section{
    position: relative;
}

.partners section::after{
    content: '';
    display: block;
    position: absolute;
    width: 35%;
    height: 1px;
    background-color: #39393980;
    margin: 20px 0;
}

.partners section:nth-child(odd)::after{
    right: 0;
}


.partners_header{
    display: flex;
    flex-direction: column;
}

.partners_header p{
    text-align: center;
}

.partners_header_img{
    background-color: azure;
    height: 220px;
    margin-bottom: 30px;
}

.partners_header_text{
    text-align: justify;
    font-size: 1.4em;
}

.features div{
    padding: 40px 0;

}

.features img{
    display: block;
    width: 4.5em;
    margin: 20px auto;
}

.features h4{
    font-weight: 400;
    font-size: 1.2em;
    text-align: center;
}
.mySwiper {
    max-width: 130px;
    height: 130px;
    padding: 20px;
}


.partners_blocks{
    display: flex;
    flex-wrap: wrap;
}

.mySwiper .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.mySwiper .swiper-slide img {
    display: block;
    width: 100%;
}

/* BRANDS */

.brands{
    padding: 0 10px;
    padding-bottom: 70px;
}

.brand_caption{
    /* width: 200px!important; */
}

.box{
    margin-top: 50px;
}

figcaption::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #00000080;
}

.brand_info{
    z-index: 1;
}

.brand_info img{
    margin-top: 50px;
}

.brand_block{
    max-height: 300px;
    max-width: 490px;
    margin: 10px 0;
}

.brand_bg{
    height: 100%;
}

.brand_title{
    transform: translateY(-20px);
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 300;
}

.brand_about{
    font-weight: 300;
    font-size: .6em;
}

/* PROJECTS */

.projects{
    padding-bottom: 70px;
}


.project_header::after{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #00000080;
}
.project_header{
    position: relative;
    height: 40vh;
    background: url(https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80) no-repeat;
    background-size: cover;
}
.project_header h1{
    font-weight: 400;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.projects_list{
    padding: 25px 10px;
}

.project_item img{
    height: 35vh;
    width: 100%;
}

.project_item{
    outline: solid 0px white;
    background-color: #fff;
    transition: all .5s cubic-bezier(1,-0.99,.14,1.8);
}


.project_item:hover{
    outline: solid 1rem white;
    transform: scale(1.1);
    cursor: pointer;
}

.project_item h3{
    margin: 0;
    font-size: .9em;
    font-weight: 500;
}

.item_title{
    padding: 5px 0;
}

.project_title{
    height: 250px;
    background-color: bisque;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    margin-bottom: 20px;
}

.project_gallery{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
}

.project_gallery div{
    margin: 5px;
    max-width: 300px;
    width: 100%;
    height: 250px;
    background-color: aliceblue;
}

@media only screen and (min-width: 768px) {
    .features{
        display: flex;
    }
    .features::after{
        left: 0;
        bottom: -30px;
    }
    .partners_blocks{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    
    .mySwiper {
        padding: 10px;
        width: 180px;
        height: 180px;
    }
    .brand_block{
        max-width: 360px;
    }
    .brands_blocks{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 20px;
    }

    .projects_list{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
    .project_gallery div{
        max-width: 400px;
        height: 410px;
    }    

}
@media only screen and (min-width: 1024px) {
    .box {
        max-width: 360px;
        width: 100%;
        position: relative;
        background: #FFF;
        padding: 200px 40px 60px;
        box-shadow: 0 15px 45px rgba(0, 0, 0, 0.254);
        overflow: hidden;

    }
    
    .box h3{
        opacity: 0
    }
    .box p{
        opacity: 0
    }
    
    .box_before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        transform: translateY(100%);
        transform-origin: top;
        transition: transform 0.5s;
    }
    
    .box:hover .box_before {
        transform: translateY(0);
        transform-origin: bottom;
        transition: transform 0.5s;
    }
    
    .box:hover h3{
        opacity: 1
    }
    .box:hover p{
        opacity: 1
    }
    
    .box h2 {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) translateY(0%) scale(1);
        top: 60px;
        font-size: 1.6em;
        font-weight: 800;
        z-index: 1;
        opacity: 0.4;
        transition: 0.5s;

    }

    .box:hover h2 {
        opacity: 1;
        color: #FFF;
        transform: translateX(-50%) translateY(-25%) scale(1.3);
    }
    
    .box h3 {
        position: relative;
        font-size: 1.5em;
        z-index: 2;
        color: #333;
        transition: 0.5s;
    }
    
    .box p {
        position: relative;
        z-index: 2;
        color: #555;
        transition: 0.5s;
    }
    
    .box:hover h3,
    .box:hover p {
        color: #FFF;
    }
    .partners_header{
        text-align: center;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 30px;
        padding: 20px 0;
    }
    .partners_header_img{
        height: 100%;
    }
    .brands_blocks{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
    }
    .brand_block{
        margin: 0;
        max-width: 600px;
    }
    .brand_info{
        width: 100%;
    }
    .brands{
        justify-content: center;
        padding-bottom: 40px;
    }
    .projects{
        padding-bottom: 0;
    }
}
@media only screen and (min-width: 1170px) {
    .partners_header{
        text-align: center;
        vertical-align: middle;
        align-items: center;
        justify-content: space-around;
        grid-column-gap: 100px;
    }
    .partners_header_img{
        margin: 0;
    }
    .partners_header_text{
        font-size: 1.6em;
    }
    .features{
        padding: 50px 0;
        justify-content: space-around;
    }
    .mySwiper {
        width: 220px;
        height: 220px;
    }
    .brands_blocks{
        display: flex;
        flex-wrap: wrap;
    }
    .brand_block{
        max-width: 370px;
        max-height: none;
        height: 600px;
    }
    .brand_title{
        font-size: 1.3em;
    }
    .brand_about{
        font-size: .9em;
    }
    .projects_list{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .projects_list a{
        max-width: 400px;
        width: 100%;
    }
    .project_item img{
        height: 65vh;
    }
    .project_gallery{
        justify-content: center;
    }
    .project_gallery div{
        height: 100vh;
    }  
    .projects_gallery_side{
        padding: 0 25px;
    }
    .projects_gallery_side h2{
        padding-bottom: 10px;
        font-weight: 400;
        font-size: 1.8em;
    }
}