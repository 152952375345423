.side_bar_hidden{
    background-color: #4B5B72;
    color: #fff;
    width: 80%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: -80%;
    transition: all .4s ease-in;
    z-index: 10;
}
.side_bar_open{
    background-color: #4B5B72;
    color: #fff;
    width: 80%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    transition: all .4s ease-in;
    z-index: 10;
    overflow-y: scroll;
}

header{
    display: flex;
    justify-content: space-between;
    padding: 25px 20px 10px 20px;
}

header h3{
    font-weight: 300;
}

.side_bar{
    list-style-type: none;
    font-weight: 300;
    font-size: 1em;
    padding-left: 20px;
}

.side_bar a{
    color: #fff;
}

ul li{
    padding-bottom: 10px;
}

.item_title{
    padding-top: 20px;
}
.item_title h6{
    font-weight: 300;
    font-size: 1.5em;
    margin-bottom: 4px;
}   

.footer{
    padding: 55px 20px;
    font-size: .8em;
    font-weight: 300;
    letter-spacing: 1.5px;
}
.footer a{
    color: #fff;
}
footer p{
    padding-bottom: 3px;
}
footer img{
    padding-right: 5px;
}