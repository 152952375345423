
.navigation_block{
    display: block;
}
.nav{
    display: none;
}
.nav_mobile{
    z-index: 3;
    width: 100%; 
    /* box-shadow: -1px 7px 24px 0px rgba(0, 0, 0, 0.089); */
    border-radius: 0 0 10px 10px;
    background-color: white;
    box-shadow: 5px 5px 8px #00000030;
    margin-bottom: 20px;
    padding: 15px 10px;
    display: grid;
    grid-template-columns: 2fr 1.5fr;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    grid-column-gap: 10px;
    justify-content: space-between;
}

.burger_ico{
    margin-left: 10px;
}

.nav_social{
    display: flex;
    justify-content: space-around;
}
  
.logo_mobile{
    height: 20px;
}
.location_header{
    display: none;
}

.mob_search{
     grid-column-start: 1;
     grid-column-end: 3;
}

.mobile_main_search{
    margin-top: 10px;
    display: flex;
    justify-content: space-around;
    vertical-align: middle;
    align-items: center;
    position: relative;
}
.mobile_main_search input{
    width: 65%;
    padding: 7px 10px;
    border-radius: 7px;
    border: 1px solid #00000030;
}

.mobile_catalog_button{
    border: 1px solid #00000070;
    font-weight: 300;
    display: flex;
    align-items: center;
    padding: 4px 6px;
    margin-left: 7px;
    border-radius: 7px;
}
.mobile_catalog_button img{
    margin-left: 4px;
}

.found_box_mob{
    position: absolute;
    width: 100%;
    left: 0;
    top: 105%;
    background-color: #fff;
    z-index: 2;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #00000030;
}

.found_tile{
    border-bottom: 1px solid #00000030;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.tile_img{
    width: 30px;
    height: 30px;
    background-color: #000;
}

.tile_nomenklatura{
    font-weight: 300;
    width: 85%;
}

.to_catalog{
    width: 100%;
    text-align: center;
    font-size:1.3em;
    text-transform: uppercase;
    padding: 5px 0;
}
@media only screen and (min-width: 768px) {
    .location_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0px 8px 16px -8px rgba(0,0,0,0.15);
        padding: 5px 10px
    }
    .nav_search img{
        width: 90%;
    }
    .location_links{
        display: flex;
        flex-direction: column;
    }
    .social_media a{
        margin-right: 10px;
    }
    .social_media img{
        height: 25px;
    }
    .dropdown_hover1{
        position: relative;
    }
    .dropdown_hover2{
        position: relative;
    }
    .dropdown{
        position: absolute;
        border-radius: 2px;
        background-color: #fff;
        z-index: 16;
        list-style-type: none;
        opacity: 1;
        transition: all .7s ease-in-out;
        box-shadow: 0 0 5px #00000030;
        overflow: hidden;
        width: 150px;
        height: 0px;
    }
    .dropdown li{
        width: 120px;
        max-width: inherit!important;
        text-align: left!important;
        padding-top: 15px;
        padding-left: 10px;
        border-bottom: #00000030 solid 1px;
        width: 100%;
    }
    .dropdown li::before{
        content: '';
        display: block;
        position: absolute;
        left: 1px;
        width: 2px;
        height: 0px;
        background-color: #000;
        transition: all .3s ease-in-out;
    }
    .dropdown li:hover{
        cursor: pointer;
        text-decoration: underline;
        background-color: #90909050;
        text-decoration: none;
    }
    .dropdown li:hover::before{
        height: 15px;
    }
    .dropdown1{
        width: 280px;
        opacity: 0;
    }
    .dropdown2{
        width: 170px;
        opacity: 0;
    }
    .dropdown3{
        opacity: 0;
    }
    .dropdown3 li::before{
        display: none!important;
    }
    .dropdown_hover1:hover .dropdown{
        opacity: 1;
        height: 123px!important;
    }
    
    .dropdown_hover2:hover .dropdown2{
        opacity: 1;
        height: 210px!important;
    }
    
    .dropdown_hover3:hover .dropdown3{
        opacity: 1;
        height: 123px!important;
    }
    .dropdown3 li:hover{
        text-decoration: none;
        cursor: inherit;
    }
    .dropdown3 li:last-child li:hover{
        cursor: pointer;
        text-decoration: underline;
    }
    .right_s_dd li{
        list-style-type: none;
        padding-right: 10px;
        text-align: right!important;
    }
    .right_s_dd li::after{
        content: '';
        display: block;
        position: absolute;
        right: 10px;
        width: 2px;
        height: 2px;
        transform: translateY(-15px);
        background-color: #fff;
        transition: all .3s ease-in-out;
    }
    .right_s_dd li:hover::after{
        height: 15px;
    }
    .dropdown_contacts{
        padding-top: 10px;
    }
    .dropdown_contacts li{
        text-align: left;
        list-style-type: none;
        font-size: 11px!important;
        padding-top: 1px;
        margin-left: 5px;
    }
    .navigation_block{
        height: inherit;
        z-index: 2000;
    }
    .nav_mobile{
        display: none;
    }

    .logo img{
        max-width:135px;
    }

    .nav{
        display: grid;
        grid-template-columns: 1fr 2fr;
        vertical-align: middle;
        align-items: center;
        padding: 10px 5px;
    }
    .menu_list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;
    }
    .menu_list div{
        display: flex;
        list-style-type: none;
        vertical-align: middle;
        align-items: center;
    }
    .menu_list li{
        text-align: center;
        font-size: .9em;
        margin-right: 45px;
    }

    .menu_list div:last-child li{
        margin: 0;
        margin-right: 20px;
    }
    .search_side{
        position: relative;
        cursor: pointer;
    }
    .search_box{
        position: absolute;
        top: 120%;
        transform: translateX(-63%);
        z-index: 1;
        display: flex;
        flex-direction: column;
    }
    .search_box input{
        padding: 8px 5px;
        border: 1px solid #f3f3f3;
        width: 300px;
        font-size: 1.2em;
        box-shadow: 1px 1px 4px #00000050;
    }
    .found_box{
        background-color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .found_item{
        width: 100%;
        padding: 10px 5px;
    }
    .found_item_img{
        min-height: 50px;
        min-width: 50px;
        background-color: #9A9A9A;
        margin-right: 10px;
    }
    .found_nomenklatura h4{
        font-weight: 400;

    }
}


@media only screen and (min-width: 1170px) {
    .nav{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}