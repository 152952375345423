.collections_block{
    margin: 15px 0;
}

.items{
    border-radius: 5px;
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 5px;
    background-color:rgb(201, 201, 201);
}

.items button{
    background-color: white;
    border: 0;
    border-radius: 5px;
    padding: 10px 15px;
}


.items button:first-child{
    margin-right: 10px;
}


.admin_side button{
    background-color: #f4f4f4;
    border: 1px solid #00000040;
    padding: 5px 10px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 1.2em;
}

.addImage button{
    padding: inherit;
}

.addImage h3{
    margin-top: 10px;
}

.addBtns button:first-child:hover{
    background-color: #4b2b2b;
}
.selected_menu{
    background-color: #838383!important;
    border: 1px solid #fff!important;
    color: #fff!important;
}

.selected_option{
    margin-top: 10px;
    border-top: 1px solid #00000030;
    padding-top: 15px;
}

.selected_option h1{
    font-weight: 400;
}

.add_form{
    margin: 20px 0;
}

.load_file{
    margin: 15px ;
}

.load_file input{
    position: absolute;
    left: 0;
}

.load_file label{
    display: block;
    width: 100%;
    cursor: pointer;
    text-align: center;
    background-color: #d4d4d4;
    width: 100%;
    padding: 10px 50px;
}

.add_form h4{
    padding: 5px 0;
}

.add_form input{
    width: 100%;
    padding: 8px 5px;
    font-size: 1.1em;
}

.items{
    margin: 20px 0;
}

.items>div{
    width: 100%;
}

.update_gallery_form{
    width: 100%;
}

.update_gallery_form input{
    padding: 5px 10px;
    font-size: 1.2em;
    width: 100%;
    margin-bottom: 5px;
}

.add{
    background-color: rgb(82, 218, 82);
    border-radius: 3px;
    border: 0;
}

.update{
    background-color: rgb(236, 255, 62)!important;
    border-radius: 3px;
    border: 0;
}

.delete{
    background-color: rgb(241, 73, 7)!important;
}


@media only screen and (min-width: 555px) {
    .admin_menu{
        display: flex;
    }
    .item{
        display: grid;
        grid-template-columns: 3fr 1fr;
        align-items: center;
    }
    .item h3{
        font-weight: 300;
    }
    .item_buttons button{
        font-size: 1em;
    }
    .item_buttons button:last-child{
        margin-bottom: 0;
    }
}

@media only screen and (min-width: 1024px) {
    .admin_side{
        display: grid;
        grid-template-columns: 1fr 3fr;
    }
    .admin_menu{
        flex-direction: column;
        margin-top: 100px;
        margin-right: 30px;
    }
    .add_bloc{
        display: grid;
        grid-template-columns: 1fr;
    }
    .add_side{
        display: grid;
        grid-template-columns: 3fr  1fr;
    }
    .add_btns{
        max-width: 200px;
        font-size: .8em;
        display: flex;
        flex-direction: column;
    }
    .add_form input{
        margin-bottom: 10px;
    }
    .add_form select{
        width: 100%;
        padding: 10px;
    }
    .update_gallery_form{
        display: grid;
        grid-template-columns: 3fr 1fr;
        grid-column-gap: 5px;
    }
    
    .items{
        position: relative;
    }

    .update_form{
        padding: 30px;
        border-radius: 10px;
        min-width: 500px;
        background-color: #fff;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        box-shadow: 1px 1px 14px #838383;
    }
    .update_inputs input{
        padding: 5px 15px;
    }
    .update_selects select{
        padding: 5px 15px;
        margin-top: 10px;
    }
    .update_selects{
        display: flex;
        flex-direction: column;
    }
}