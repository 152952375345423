@font-face {
  font-family: Sofia;
  src: url(../public/Sofia\ Pro\ Regular\ Az.otf);
}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Sofia, sans-serif;
  scroll-behavior: smooth;
  transition: .3s all ease-in-out;
}
a{
  color: black;
  text-decoration: none;
}
.app_screen{
  position: relative;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-button-next{
  color: black!important;
}
.swiper-button-prev{
  color: black!important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill{
  background-color: bisque!important;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-vertical.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-vertical>.swiper-pagination-bullets .swiper-pagination-bullet{
  background-color: bisque;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.light_shade{
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: #00000000;
  transition: all 1s linear;
  pointer-events: none;
  z-index: 5;
  position: fixed;
}

.dark_shade{
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: #00000090;
  transition: all .4s linear;
  z-index: 5;
  overflow-y: hidden;
  position: fixed;
}
.to_top{
  display: block;
  position: fixed;
  z-index: 2;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background-color: #D9D9D9;
  opacity: 0;
  transition: none;
}

.to_top img{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.swiper-slide{
  background-color: #00000000;
}



@media only screen and (min-width: 1170px) {
  .container{
    /* max-width: 1170px; */
    width: 100%;
    overflow: hidden;
    margin: 0 auto;
  }
}