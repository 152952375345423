*{
    list-style: none;
}

.showroom{
    padding: 0 15px;
    max-width: 1250px;
    margin: 0 auto;
}

.showroom_title{
    font-weight: 400;
    font-size: 1.7em;
    padding: 15px 0;
}

.showroom_header_img{
    height: 300px;
    margin-bottom: 15px;
}

.showroom_info p{
    text-align: justify;
    font-size: 1.2em;
    font-weight: 300;
}

.showroom_info_list{
    padding: 20px 0;
}

.showroom_info_list > li{
    padding-bottom: 30px;
    font-weight: 300;
}

.showroom_info_list > li:last-child{
    font-size: 1.1em;
    font-weight: 500;
    text-decoration: underline;
    padding-bottom: 0;
}

.showroom_info_list > li ul li:first-child{
    font-weight: 500;
    font-size: 1.1em;
}

.showroom_gallery{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.showroom_gallery img{
    width: 100%;
    padding-bottom: 40px;
}
@media only screen and (min-width: 768px) {
    .showroom_info_list{
        display: flex;
        justify-content: space-between;
    }

    
    .showroom_gallery > div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .showroom_gallery img{
        max-width: 510px;   
    }
}
@media only screen and (min-width: 1024px) {
    

    .showroom_header{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 40px;
        align-items: center;
    }
    .showroom_header_img{
        height: 100%;
        margin: 0;
    }

    .showroom_info{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    
    .showroom_info p{
        font-size: 1.1em;
    }
    
    .showroom_info_list{
        flex-direction: column;
    }

    .showroom_gallery{
        padding-top: 50px;
    }
}

@media only screen and (min-width: 1170px) {
    .showroom{
        padding: 0 15px;
        max-width: 1650px;
    }
    .showroom_gallery{
        max-width: 1250px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1px;
        align-items: center;
    }
    .showroom_gallery>div{
        justify-content: flex-end;
    }
}
