.home{
    position: relative;
}

.to_top{
    display: block;
    position: fixed;
    z-index: 2;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: #D9D9D9;
    opacity: 0;
}

.to_top img{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}




/* COLLECTIONS */

.images li{
    height: 35vh;
}

.collections_section{
    display: none;
    padding: 10px 20px;
}

.collections_section h3{
    font-weight: 300;
    padding-bottom: 5px;
    text-transform: capitalize;
}

.images{
    list-style-type: none;
}

.images li{
    border-radius: 8px;
    margin-bottom: 15px;
    position: relative;
}

.images li:last-child{
    margin-bottom: 0;
}

.images li h2{
    display: inline-block;
    text-transform: uppercase;
    font-weight: 400;
    padding: 10px 25px;
    background-color: #AAC7FF;
    border-radius: 0 0 8px 0;
}

/* CATALOGUES */

.catalogue_section{
    margin-top: 20px;
}

.catalogue_section h2{
    font-weight: 400;
    padding: 5px 0;
}

.catalog_grid{
    display: grid;
}

.catalog_grid item2{
    height: 200px;
}

.item{
    height: 240px;
    background-color: #000000;
    margin-bottom: 10px;
    position: relative;
}

.item:last-child{
    margin-bottom: 0;
}

.item h3{
    position: absolute;
    bottom: 25px;
    color: white;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(75,91,114,0.42620798319327735) 51%, rgba(75,91,114,0) 100%);
    backdrop-filter: blur(15px);
    padding: 10px 15px 10px 3px;
    /* border-radius: 0 15px 15px 0; */
    text-transform: uppercase;
    font-weight: 500;
}

/* ABOUT  */

.about_us h2{
    text-align: center;
    padding-top: 15px;
    font-weight: 400;
}

.list_container>div{
    padding: 15px 10px;
    min-height: 150px;
    border-bottom: 1px solid #00000070;
    display: flex;
    align-items: center;
}

.ico_side{
    padding-right: 20px;
}
.ico_side img{
    max-width: 40px;
}

.list_container:last-child div:last-child{
    border-bottom: 0;
}

.list_container h3{
    font-weight: 500;
    padding-bottom: 5px;
}

.list_container p{
    font-size: .9em;
    font-weight: 300;
}
.gallery{
    display: none;
}

@media only screen and (min-width: 768px) {
    .container{
        padding: 0 10px;
    }
    .collections_section{
        display: none;
    }

    /* CATALOGUE */

    .catalog_grid{
        display: flex;
        flex-wrap: wrap;
        grid-column-gap: 10px;
        justify-content: center;
    }
    .catalog_grid .item{
        width: 49%;
    }

    /* ABOUT */

    .about_us{
        padding-bottom: 40px;
    }

    .list_container{
        display: flex;
        justify-content: space-around;
    }

    .list_container>div{
        max-width: 300px;
        width: 100%;
    }
    
    .list_container:last-child > div:last-child{
        border-bottom: 1px solid #00000020;
    }    
    .ico_side img{
        max-width: 60px;
    }
}


@media only screen and (min-width: 1024px) {
    /* CATALOG */
    .catalog_grid{
        display: grid;
        grid-template-columns: 1fr 1fr 2fr;
        grid-gap: 5px;
        grid-auto-rows: 240px;
    }
    .catalog_grid .item{
        height: inherit;
        margin: 0;
        width: 100%;
    }
    .item0{
        grid-column: 1/2;
        grid-row: 1/3;
    }
    .item1{
        grid-column: 2/3;
        grid-row: 1/2;
    }
    .item2{
        grid-column: 2/3;
        grid-row: 2/3;
    }
    .item3{
        grid-column: 3/4;
        grid-row: 1/3;
    }
    .item4{
        grid-column: 3/4;
        grid-row: 3/4;
    }
    .item5{
        grid-column: 3/4;
        grid-row: 4/5;
    }
    .item6{
        grid-column: 1/3;
        grid-row: 3/5;
    }

    /* ABOUT */
    .about_us{
        padding: 10px 0;
    }
    .about_us h2{
        display: none;
    }

    .about_img{
        /* background-color: #4B5B7280; */
        height: 635px;
        width: 80%;
        margin: 0px auto;
    }
    .list_container{
        justify-content: center;
    }
    .list_container div{
        max-width: inherit;
        padding: 40px;
    }
    .list_container div:first-child{
        border-right: 1px solid #00000080;
    }
    .list_container:last-child div{
        border-bottom: 0!important;
    }
    .list_container p{
        font-size: 1.2em;
    }
    .list_container h3{
        text-align: center;
        border-bottom: 1px solid #00000050;
        margin-bottom: 10px;
    }
    
}


@media only screen and (min-width: 1170px) {
    /* CATALOG */
    .catalogue_section h2{
        font-size: 2em;
        padding: 10px 0;
    }
    /* ABOUT */
    .about_list{
        max-width: 1170px;
        margin: 0 auto;
    }
    .gallery{
        display: block;
        padding: 50px 0;
        position: relative;
        max-width: 1170px;
        margin: 0 auto;
        transition: all 2s ease-in-out;
    }

    .center_img{
        width: 750px;
        height: 510px;
        background-color: aliceblue;
        margin: 0 auto;
        border-radius: 15px;
    }
    .l_sm_img{
        animation-name: l_img;
        animation-duration: 10s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
    /* @keyframes l_img{
        0%{
            top: 0;
        }50%{
            top: 300px;
        }100%{
            top: 0;
        }
    } */
    .r_sm_img{
        animation-name: r_img;
        animation-duration: 10s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
    }
    /* @keyframes r_img{
        0%{
            bottom: 50px;
        }50%{
            bottom: 350px;
        }100%{
            bottom: 50px;
        }
    } */
    .sm_img{
        width: 350px;
        height: 300px;
        background-color: #D9D9D9;
        position: absolute;
        border-radius: 10px;
    }
    .sm_img:first-child{
        top: 80px;
    }
    .sm_img:last-of-type{
        right: 0;
        bottom: 150px;
    }
    .gallery p{
        max-width: 770px;
        text-align: center;
        margin: 0 auto;
        padding-top: 30px;
    }
}
