 @keyframes ring {
	 0%, 100% {
		 transform: rotate(0deg);
	}
	 5% {
		 transform: rotate(-30deg);
	}
	 10% {
		 transform: rotate(25deg);
	}
	 15% {
		 transform: rotate(-25deg);
	}
	 20% {
		 transform: rotate(20deg);
	}
	 25% {
		 transform: rotate(-20deg);
	}
	 30% {
		 transform: rotate(15deg);
	}
	 35% {
		 transform: rotate(-15deg);
	}
	 40% {
		 transform: rotate(15deg);
	}
	 45% {
		 transform: rotate(0deg);
	}
}
 .bell {
	 animation-name: ring;
	 animation-duration: 2s;
	 animation-iteration-count: inherit;
     animation-delay: 10s;
     animation-fill-mode: both;
	 height: 45px;
	 width: 45px;
}

.bell.delayed {
	animation-delay: 10s;
}

.wp_ico{
    position: fixed;
    bottom: 20px;
    right: 80px;
    z-index: 2;
}